import { setAuthToken } from '../../utils'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_SEAMLESS_LOGIN_REQUEST,
  USER_SEAMLESS_LOGIN_SUCCESS,
  USER_SEAMLESS_LOGIN_FAILURE,
  USER_ROUTE_REQUEST,
  USER_ROUTE_SUCCESS,
  USER_ROUTE_FAILURE,
  SWITCH_USER,
  LOG_OUT
} from './types'
import Cookies from 'js-cookie'

const initialState: State = {
  user: null,
  loading: false,
  error: '',
  isAuthenticated: false,
  isEmailVerified: false,
  config: null,
  trySeamlessLogin: true
}

type State = {
  user: any
  loading: boolean
  error: string
  isAuthenticated: boolean
  isEmailVerified: boolean
  config: any
  trySeamlessLogin: boolean
}

type ActionEvent = {
  type: string
  payload: any
}

export default function (state = initialState, action: ActionEvent): State {
  const { type, payload } = action
  switch (type) {
    case USER_ROUTE_REQUEST:
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        user: payload
      }

    case USER_SEAMLESS_LOGIN_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        trySeamlessLogin: true
      }

    case USER_ROUTE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        isEmailVerified: true,
        config: payload
      }

    case USER_LOGIN_SUCCESS:
      localStorage.setItem('access-token', payload.accessToken)
      setAuthToken()
      return {
        ...state,
        error: '',
        user: payload,
        isAuthenticated: true,
        loading: false
      }

    case USER_SEAMLESS_LOGIN_SUCCESS:
      localStorage.setItem('access-token', payload.accessToken)
      setAuthToken()
      return {
        ...state,
        error: '',
        user: payload,
        isAuthenticated: true,
        loading: false,
        trySeamlessLogin: false
      }

    case SWITCH_USER:
      return {
        ...state,
        error: '',
        isEmailVerified: false,
        config: null,
        loading: false
      }

    case USER_LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: payload
      }

    case USER_SEAMLESS_LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: payload,
        trySeamlessLogin: false
      }

    case USER_ROUTE_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
        error: payload,
        isEmailVerified: false,
        config: null,
        trySeamlessLogin: false
      }

    case LOG_OUT:
      const response = Cookies.get('unauthorized')
      if (response) {
        Cookies.remove('unauthorized')
      }
      localStorage.removeItem('access-token')
      sessionStorage.removeItem('redirect_route')
      return {
        user: null,
        isAuthenticated: false,
        loading: false,
        error: '',
        isEmailVerified: false,
        config: null,
        trySeamlessLogin: false
      }

    default:
      return state
  }
}
