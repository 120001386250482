import { LOG_OUT } from '../auth/types'
import {
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
  GET_STATS_FAILURE,
  RENEW_CACHE_REQUEST,
  RENEW_CACHE_SUCCESS,
  RENEW_CACHE_FAILURE
} from './types'

const initialState: State = {
  loading: false,
  error: '',
  stats: null
}

type State = {
  loading: boolean
  error: string
  stats: object | null
}

type ActionEvent = {
  type: string
  payload: any
}

export default function (state = initialState, action: ActionEvent): State {
  const { type, payload } = action
  switch (type) {
    case RENEW_CACHE_REQUEST:
    case GET_STATS_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      }
    case RENEW_CACHE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false
      }
    case GET_STATS_FAILURE:
    case RENEW_CACHE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case GET_STATS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        stats: payload
      }
    case LOG_OUT:
      return initialState
    default:
      return {
        ...state,
        error: ''
      }
  }
}
