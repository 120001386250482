import { v4 as uuidv4 } from 'uuid'
import { REMOVE_ALERT, SET_ALERT, REMOVE_EXISTING_ALERTS } from './types'
const { PORT } = require('../../config');

// sets an alert message
export const setAlert =
  (type: string, message: string) => async (dispatch: any) => {
    const id = uuidv4()
    dispatch({
      type: SET_ALERT,
      payload: { id, type, message }
    })

    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
        payload: id
      })
    }, PORT)
  }

export const removeExistingAlerts = () => async (dispatch: any) => {
  dispatch({
    type: REMOVE_EXISTING_ALERTS
  })
}
