export const USER_ROUTE_REQUEST = 'USER_ROUTE_REQUEST'
export const USER_ROUTE_SUCCESS = 'USER_ROUTE_SUCCESS'
export const USER_ROUTE_FAILURE = 'USER_ROUTE_FAILURE'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'

export const USER_SEAMLESS_LOGIN_REQUEST = 'USER_SEAMLESS_LOGIN_REQUEST'
export const USER_SEAMLESS_LOGIN_SUCCESS = 'USER_SEAMLESS_LOGIN_SUCCESS'
export const USER_SEAMLESS_LOGIN_FAILURE = 'USER_SEAMLESS_LOGIN_FAILURE'

export const SWITCH_USER = 'SWITCH_USER'
export const LOG_OUT = 'LOG_OUT'
