/* istanbul ignore file */
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// reducers
import auth from './auth/reducer'
import alert from './alert/reducer'
import docs from './docs/reducer'
import admin from './admin/reducer'

// config for redux-persist
const persistConfig = {
  key: 'root',
  storage
}

// combines all reducers to form a single store
const rootReducer = combineReducers({
  auth,
  alert,
  docs,
  admin
})

export default persistReducer(persistConfig, rootReducer)
