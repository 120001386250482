import { LOG_OUT } from '../auth/types'
import {
  FETCH_FILE_FAILURE,
  FETCH_FILE_REQUEST,
  FETCH_FILE_SUCCESS
} from './types'

const initialState: State = {
  file: null,
  loading: false,
  error: '',
  fileNotFound: false
}

type State = {
  file: any
  loading: boolean
  error: string
  fileNotFound: boolean
}

type ActionEvent = {
  type: string
  payload: any
}

export default function (state = initialState, action: ActionEvent): State {
  const { type, payload } = action

  switch (type) {
    case FETCH_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        fileNotFound: false
      }

    case FETCH_FILE_SUCCESS:
      return {
        ...state,
        file: payload,
        loading: false,
        error: '',
        fileNotFound: false
      }

    case FETCH_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        file: null,
        fileNotFound: true,
        error: payload
      }

    case LOG_OUT:
      return initialState

    default:
      return {
        ...state,
        error: ''
      }
  }
}
