/* istanbul ignore file */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import './index.css'
import App from './App'
import TagManager from 'react-gtm-module'
import {
  GTM_ID_INTERNAL,
  GTM_ID_EXTERNAL,
  INTERNAL_PORTAL_URL,
  EXTERNAL_PORTAL_URL
} from './constants/constants'

const getGtmIdAccToOrigin = () => {
  const origin = window.location.origin
  switch (origin) {
    case INTERNAL_PORTAL_URL:
      return GTM_ID_INTERNAL
    case EXTERNAL_PORTAL_URL:
      return GTM_ID_EXTERNAL
    default:
      return undefined
  }
}

const tagManagerArgs = {
  gtmId: getGtmIdAccToOrigin()
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <PersistGate persistor={persistor}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </PersistGate>
    </Router>
  </Provider>,
  document.getElementsByTagName('body')[0]
)
