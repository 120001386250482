import axios from 'axios'
import {
  HOTJAR_ID_INTERNAL,
  HOTJAR_ID_EXTERNAL,
  HOTJAR_ID_LOCAL,
  INTERNAL_PORTAL_URL,
  EXTERNAL_PORTAL_URL
} from '../constants/constants'

// sets auth token in headers
export const setAuthToken = (): void => {
  const token = localStorage.getItem('access-token')

  if (token) {
    axios.defaults.headers.common['access-token'] = token
  } else {
    delete axios.defaults.headers.common['access-token']
  }
}

const getHotjarIdAccToOrigin = () => {
  const origin = window.location.origin
  switch (origin) {
    case INTERNAL_PORTAL_URL:
      return HOTJAR_ID_INTERNAL
    case EXTERNAL_PORTAL_URL:
      return HOTJAR_ID_EXTERNAL
    default:
      return HOTJAR_ID_LOCAL
  }
}

export const loadHotjarScript = () => {
  const hotJarScript = document.createElement('script')
  hotJarScript.text = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${getHotjarIdAccToOrigin()},hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
  document.body.appendChild(hotJarScript)
}

export const removeHtmlElementsByTagName = (selector: string) => {
  const elements = document.getElementsByTagName(selector)
  for (let i = 0; i < elements.length; i++) {
    if (elements[i]) elements[i].remove()
  }
}
