/* istanbul ignore file */
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'

const middlewares = [thunk]
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
)
export const persistor = persistStore(store)
