import React from 'react'
import { Helmet } from 'react-helmet'
import Spinner from './Spinner'

export const Loading: React.VoidFunctionComponent<{ customStyles?: object }> =
  ({ customStyles = {} }): JSX.Element => (
    <>
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 999,
          position: 'absolute',
          background: '#fff',
          ...customStyles
        }}
      >
        <Spinner />
      </div>
    </>
  )

export default React.memo(Loading)
