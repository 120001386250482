import { REMOVE_ALERT, REMOVE_EXISTING_ALERTS, SET_ALERT } from './types'

const initialState: AlertState = {
  alerts: []
}

type Alert = {
  id: string
  type: 'success' | 'info' | 'warning' | 'danger'
  message: string
}

type AlertState = {
  alerts: Alert[]
}

type ActionEvent = {
  type: string
  payload: any
}

export default function (
  state = initialState,
  action: ActionEvent
): AlertState {
  const { type, payload } = action
  switch (type) {
    case SET_ALERT:
      return {
        alerts: [...state.alerts, payload]
      }

    case REMOVE_ALERT:
      return {
        alerts: state.alerts.filter((alert) => alert.id !== payload)
      }
    case REMOVE_EXISTING_ALERTS:
      return {
        alerts: []
      }
    default:
      return state
  }
}
