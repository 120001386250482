import React from 'react'
import styles from '../styles/Spinner.module.css'

const Spinner: React.VoidFunctionComponent<{ customStyles?: object }> = ({
  customStyles = {}
}): JSX.Element => (
  <div className={styles.ldsRing} style={customStyles}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default React.memo(Spinner)
