import React, { useEffect, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAuthToken } from './utils'
import Loading from './components/Loading'
import { removeExistingAlerts } from './redux/alert/actions'

// Components
const PrivateRoute = lazy(() => import('./components/PrivateRoute'))
const AdminRoute = lazy(() => import('./components/AdminRoute'))
const Alert = lazy(() => import('./components/Alert'))
const ErrorBoundary = lazy(() => import('./components/ErrorBoundary'))
const DropdownMenu = lazy(() => import('./components/DropdownMenu'))

// Pages
const Login = lazy(() => import('./views/Login/Login'))
const Docs = lazy(() => import('./views/Docs/Docs'))
const Admin = lazy(() => import('./views/Admin/Admin'))

export const App: React.VoidFunctionComponent<{
  removeExistingAlerts: () => void
}> = ({ removeExistingAlerts }): JSX.Element => {
  useEffect(() => {
    setAuthToken()
    removeExistingAlerts()
  }, [])

  return (
    <Suspense fallback={<Loading />}>
      {/* @ts-ignore */}
      <ErrorBoundary>
        <Alert />
        <DropdownMenu />
        <Switch>
          <Route path="/login" exact component={Login} />
          <AdminRoute component={Admin} path="/admin" exact />
          <PrivateRoute component={Docs} path="/" />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  )
}

export default connect(null, { removeExistingAlerts })(App)
